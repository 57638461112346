import React from "react";
import { Link } from "gatsby";

import logo from "../img/logo.jpg";
import linkedin from "../img/social/linkedin.png";
import medium from "../img/social/medium.png";
import angelList from "../img/social/angelList.png";
import vimeo from "../img/social/vimeo.svg";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered">
          <img
            src={logo}
            alt="Philinvested"
            style={{ width: "5em", height: "5em", borderRadius: "90px" }}
          />
        </div>
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link to="/" className="navbar-item">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/about">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/books">
                        My Booklist
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/blog">
                        My Blog
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/projects">
                        My Projects
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-5 social">
                <a
                  title="linkedin"
                  href="https://www.linkedin.com/in/philinvested/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={linkedin}
                    alt="linkedin"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a
                  title="angelList"
                  href="https://angel.co/philinvested"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={angelList}
                    alt="angelList"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a
                  title="medium"
                  href="https://medium.com/@philinvested"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={medium}
                    alt="medium"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a
                  title="vimeo"
                  href="https://vimeo.com/lucreativity"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={vimeo}
                    alt="Vimeo"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
